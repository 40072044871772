<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div
            v-if="hasCredentials"
            id="bluemoon-guarantor-container"
        ></div>
        <div
            v-else
            class="w-full text-center align-center mt-4"
        >
            <p>please set up the blue moon property to continue</p>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapActions, mapGetters } from 'vuex';
import { BM_CREDENTIALS_ERROR, BM_PROPERTY_ERROR } from '@/utils/constants/leasing.js';
import { currentDate } from '@/utils/Date.js';
import { formatQuotedRentAmount } from '@/utils/Amount';

export default {
    name: 'GuarantorContractReview',
    components: { Loader },
    mixins: [NotifyMixin],
    data() {
        return {
            loading: false,
            hasCredentials: false,
            applicants: [],
            property: null,
            account_email: '',
            status: '',
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            getGuarantor: 'application/getGuarantor',
            getDocumentInstance: 'application/getDocumentInstance',
            getbluemoon_credentials: 'application/getbluemoon_credentials',
        }),
    },

    watch: {
        status: async function () {
            if (this.status !== '') {
                if (this.getGuarantor.application?.document_package_instance_id) {
                    await this.fetchDocumentIntance(this.getGuarantor.application?.document_package_instance_id);
                } else {
                    this.checkApplicationStatus(this.status);
                }
            }
        },
    },

    async mounted() {
        await this.fetchCurrentApplicationByOccupancyId(this.currentProfile.occupancyId, this.currentProfile.contactId);
    },

    methods: {
        ...mapActions({
            setGuarantor: 'application/setGuarantor',
            setApplication: 'application/setApplication',
            setDocumentInstance: 'application/setDocumentInstance',
            setbluemoon_credentials: 'application/setbluemoon_credentials',
        }),

        formatQuotedRentAmount,

        fetchbluemoon_credentials() {
            this.loading = true;
            this.$partnerDataProvider
                .getOne('community_blue_moon', {
                    customerUUID: this.currentProfile.customerId,
                    communityUUID: this.currentProfile.communityId,
                    purpose: 'AplicationLeasingGeneration', // Check this with Nick later.
                })
                .then(res => {
                    if (res.platformData) {
                        this.hasCredentials = true;
                        this.setbluemoon_credentials(res.platformData);
                        this.getAuthBlueMoonToken();
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.hasCredentials = false;
                    this.notifyError(BM_CREDENTIALS_ERROR);
                });
        },

        async fetchCurrentApplicationByOccupancyId(occupancy_id, contact_id) {
            this.loading = true;
            await this.$leasingServiceDataProvider
                .getOne('household', {
                    occupancy_id,
                })
                .then(async res => {
                    this.applicants = res[0]?.persons
                        .filter(person => person.person_role === 'Applicant')
                        .map(applicant => {
                            return `${applicant.first_name} ${applicant.last_name}`;
                        });
                    let household = {
                        ...res[0],
                        guarantor: res[0]?.persons
                            .filter(person => person.person_role === 'Guarantor' && person.contact_id === contact_id)
                            .map(guarantor => {
                                return {
                                    ...guarantor,
                                    applicationProcessStatus: guarantor.applicationProcessStatus[0]?.application_process_status,
                                };
                            }),
                    };
                    delete household.persons;
                    household = {
                        ...household,
                        ...household.guarantor[0],
                        application: household.guarantor[0]?.application[0]?.application,
                        expected_lease_duration: `${household.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(household.quoted_rent_amount),
                        pet: [],
                        vehicle: [],
                    };
                    delete household.householdCombinedStatus;
                    delete household.householdHasNote;
                    delete household.backgroundScreening;
                    delete household.identityVerification;
                    delete household.guarantor;
                    delete household.relation;
                    this.status = household.applicationProcessStatus;
                    this.setGuarantor(household);
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => (this.loading = false));
        },

        async fetchDocumentIntance(document_id) {
            await this.$leasingServiceDataProvider
                .getOne('document_instance', {
                    document_package_instance_id: document_id,
                    business_unit_id: this.currentProfile.communityId,
                    customer_id: this.currentProfile.customerId,
                })
                .then(res => {
                    this.setDocumentInstance({
                        ...res.payload,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                    this.checkApplicationStatus(this.status);
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        checkApplicationStatus(status) {
            if (status.toLowerCase() === 'application submitted - not paid') {
                this.$router.push({ name: 'application_service.application_payment_guarantor' });
            } else if (
                status.toLowerCase() === 'background screening' ||
                status.toLowerCase() === 'mitigation' ||
                status.toLowerCase() === 'accepted' ||
                status.toLowerCase() === 'declined'
            ) {
                this.$router.push({ name: 'application_service.confirmation' });
            } else if (status.toLowerCase() === 'identity verification') {
                this.$router.push({ name: 'application_service.background_screening_guarantor' });
            } else this.$nextTick(this.fetchbluemoon_credentials);
        },

        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials.username,
                        password: this.getbluemoon_credentials.password,
                        scope: 'full',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials.client_id,
                        client_secret: this.getbluemoon_credentials.client_secret,
                    },
                })
                .then(async res => {
                    this.token = res.access_token;
                    await this.getAccount(this.getbluemoon_credentials?.serial_number, res.access_token);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        getAccount(accountId, token) {
            this.loading = true;
            this.$bmServiceDataProvider
                .getList('account', {
                    id: accountId,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.account_email = res.data.email;
                    if (this.account_email) await this.getProperty(accountId, token);
                })
                .catch(() => {
                    this.notifyError(BM_PROPERTY_ERROR);
                    this.loading = false;
                });
        },

        getProperty(accountId, token) {
            this.loading = true;
            this.$bmServiceDataProvider
                .getList('property', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.property = res.data.find(property => property.account_id === accountId);
                    if (this.property) await this.getAuthBlueMoonTokenForGuarantor(this.property.id);
                })
                .catch(() => {
                    this.notifyError('An error occurred getting the property from Blue Moon');
                    this.loading = false;
                });
        },

        getAuthBlueMoonTokenForGuarantor(property_id) {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials.username, // Fake property (15430) serial number
                        password: this.getbluemoon_credentials.password,
                        scope: `guarantor:${this.getDocumentInstance?.key}`,
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials.client_id,
                        client_secret: this.getbluemoon_credentials.client_secret,
                    },
                })
                .then(async res => {
                    this.token = res.access_token;
                    await this.leaseApplication(res.access_token, property_id);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        leaseApplication(token, property_id) {
            this.loading = true;
            const params = {
                view: 'esignature',

                enableNavigation: false,

                showNavigationButtons: true,

                includeFieldData: true,

                guarantorKey: this.getDocumentInstance?.key,

                preleaseData: {
                    owner_name: this.property.name,
                    owner_email: this.account_email,
                    owner_phone: this.property.telephone,
                    residents: this.applicants,
                },

                fieldData: {
                    guarantor_name: `${this.getGuarantor.first_name} ${this.getGuarantor.last_name}`,
                    email: this.getGuarantor.email,
                    phone: this.getGuarantor.cell_phone,
                },
            };
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(token, property_id);

            const guarantorElement = bluemoonElements.load('bluemoon-guarantor-container', 'bm-guarantor', params);

            guarantorElement.addEventListener('onElementLoaded', () => {
                this.loading = false;
            });

            guarantorElement.addEventListener('onEsignatureComplete', e => {
                if (this.getGuarantor.application?.document_package_instance_id) {
                    this.handleUpdateDocument({
                        ...this.getDocumentInstance,
                        document_url: e.detail.document_url,
                        date_signed: e.detail.date_initiated,
                        date_initiated: e.detail.date_initiated,
                        id: e.detail.id,
                        key: e.detail.key,
                    });
                } else {
                    this.handleSaveDocument({
                        ...this.getDocumentInstance,
                        document_url: e.detail.document_url,
                        date_signed: e.detail.date_initiated,
                        date_initiated: e.detail.date_initiated,
                        id: e.detail.id,
                        key: e.detail.key,
                    });
                }
            });
        },

        handleUpdateDocument(payload) {
            this.$leasingServiceDataProvider
                .patch('update_package_instance', {
                    document_package_instance_id: this.getGuarantor.application?.document_package_instance_id,
                    business_unit_id: this.currentProfile.communityId,
                    customer_id: this.currentProfile.customerId,
                    data: {
                        partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85', // Bluemoon's id
                        document_type_name: 'BlueMoon Application', // BlueMoon Guarantor - Pre Lease Contract
                        document_initiated_by_app: 'Blue Moon',
                        occupancy_id: this.currentProfile.occupancyId,
                        payload: payload,
                    },
                })
                .then(() => {
                    this.setDocumentInstance({
                        ...this.getDocumentInstance,
                        ...payload,
                        document_package_instance_id: this.getGuarantor.application?.document_package_instance_id,
                    });
                    /* Uncomment when the endpoint is ready to use */
                    this.updateApplicationRecord({
                        ...this.getDocumentInstance,
                        ...payload,
                        document_package_instance_id: this.getGuarantor.application?.document_package_instance_id,
                    });
                })
                .catch(() => {
                    this.notifyError('Something went wrong saving the document, please try again later');
                })
                .finally(() => (this.loading = false));
        },

        handleSaveDocument(payload) {
            this.$leasingServiceDataProvider
                .create('create_package_instance', {
                    data: {
                        business_unit_id: this.currentProfile.communityId,
                        customer_id: this.currentProfile.customerId,
                        partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85', // Bluemoon's id
                        document_type_name: 'BlueMoon Application', // BlueMoon Guarantor - Pre Lease Contract
                        document_initiated_by_app: 'Blue Moon',
                        occupancy_id: this.currentProfile.occupancyId,
                        payload: payload,
                    },
                })
                .then(res => {
                    this.setDocumentInstance({
                        ...this.getDocumentInstance,
                        ...res.payload,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                    /* Uncomment when the endpoint is ready to use */
                    this.createApplicationRecord({
                        ...this.getDocumentInstance,
                        ...res.payload,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                })
                .catch(() => {
                    this.notifyError('Something went wrong saving the document, please try again later');
                })
                .finally(() => (this.loading = false));
        },

        updateApplicationRecord(document_instance) {
            const guarantor_object = { ...this.getGuarantor };
            const currentParty = guarantor_object.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete guarantor_object.application;
            delete guarantor_object.applicationProcessStatus;
            delete guarantor_object.person_id;
            this.$leasingServiceDataProvider
                .patch('application', {
                    application_id: this.getGuarantor.application?.application_id,
                    data: {
                        ...guarantor_object,
                        occupancy_id: this.currentProfile.occupancyId,
                        lease_id: this.currentProfile.occupancyId,
                        birth_date: document_instance.birth_date,
                        cell_phone: document_instance.phone,
                        submission_date: currentDate,
                        employment_income: Number(document_instance.gross_monthly_income) ?? 0,
                        employment_status: document_instance.employer_name ?? 1,
                        marital_status:
                            document_instance.marital_status.charAt(0).toUpperCase() + document_instance.marital_status.slice(1),
                        current_address: document_instance.current_address,
                        current_city: document_instance.current_city,
                        current_st: document_instance.current_st,
                        current_zip: document_instance.current_zip,
                        ssn: document_instance.social_security_number,
                        bluemoon_application_id: document_instance.id,
                        document_url: document_instance.document_url,
                        document_package_instance_id: this.getGuarantor.application?.document_package_instance_id,
                        application_process_status: 'Application submitted - Not paid',
                        event_description: 'Application submitted - Not paid',
                        event_name: 'Application submitted',
                        guarantor_key: document_instance.key,
                        work_phone: document_instance.work_phone,
                        is_primary: currentParty?.is_primary,
                    },
                })
                .then(res => {
                    this.setApplication(res);
                    this.$router.push({ name: 'application_service.application_payment_guarantor' });
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => (this.loading = false));
        },

        createApplicationRecord(document_instance) {
            const guarantor_object = { ...this.getGuarantor };
            const currentPerson = guarantor_object.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = guarantor_object.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete guarantor_object.application;
            delete guarantor_object.applicationProcessStatus;
            delete guarantor_object.person_id;
            this.$leasingServiceDataProvider
                .create('application', {
                    data: {
                        ...guarantor_object,
                        lease_id: this.currentProfile.occupancyId,
                        birth_date: document_instance.birth_date,
                        cell_phone: document_instance.phone,
                        employment_income: document_instance.gross_monthly_income,
                        employment_status: document_instance.employer_name ?? 1,
                        marital_status:
                            document_instance.marital_status.charAt(0).toUpperCase() + document_instance.marital_status.slice(1),
                        submission_date: currentDate,
                        current_address: document_instance.current_address,
                        current_city: document_instance.current_city,
                        current_st: document_instance.current_st,
                        current_zip: document_instance.current_zip,
                        ssn: document_instance.social_security_number,
                        bluemoon_application_id: document_instance.id,
                        document_url: document_instance.document_url,
                        document_package_instance_id: document_instance.document_package_instance_id,
                        application_process_status: 'Application submitted - Not paid',
                        event_description: 'Application submitted - Not paid',
                        event_name: 'Application submitted',
                        guarantor_key: document_instance.key,
                        work_phone: document_instance.work_phone,
                        is_primary: currentParty?.is_primary,
                        person_role: currentPerson?.person_role,
                    },
                })
                .then(res => {
                    this.setApplication(res);
                    this.$router.push({ name: 'application_service.application_payment_guarantor' });
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
